import styled from 'styled-components'
import { easings } from '../../../styles/vars/easings.style'

const BaseSplitTextWrapper = styled.span`
  display: block;

  .split__mask {
    overflow: hidden;
  }

  .split__text {
    will-change: transform;
    transform: translateY(105%);
    transition: transform 0.5s ${easings.inOut.default};

    [data-inview='false'] & {
      transition-delay: 0s;
    }
  }

  [data-inview='true'] &.split .split__mask .split__text {
    transform: translate(0, 0);
  }
`

export const SplitTextWrapper = styled(BaseSplitTextWrapper)`
  .split__mask .split__text {
    ${({ delay }) => {
      let itemDelay = 0.05

      return `transition-delay: calc(${delay}s + ${itemDelay} * var(--splitTextDelay));`
    }}
  }
`
